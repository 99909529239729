/*
  Style for page Category and Tag
*/

.dash {
  margin: 0 0.5rem 0.6rem 0.5rem;
  border-bottom: 2px dotted var(--dash-color);
}

#page-category,
#page-tag {
  ul > li {
    line-height: 1.5rem;
    padding: 0.6rem 0;

    /* dot */
    &::before {
      background: #999999;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: block;
      content: '';
      position: relative;
      top: 0.6rem;
      margin-right: 0.5rem;
    }

    /* post's title */
    > a {
      @extend %no-bottom-border;

      font-size: 1.1rem;
    }
  }
}

/* tag icon */
#page-tag h1 > i {
  font-size: 1.2rem;
}

#page-category h1 > i {
  font-size: 1.25rem;
}

#page-category,
#page-tag,
#access-lastmod {
  a:hover {
    @extend %link-hover;

    margin-bottom: -1px; /* Avoid jumping */
  }
}

@media all and (max-width: 576px) {
  #page-category,
  #page-tag {
    ul > li {
      &::before {
        margin: 0 0.5rem;
      }

      > a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
